<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Daily Usage</h3>

			<div class="col-3 d-flex align-items-center justify-content-between">
				<div>
					<b-form-radio-group
						id="btn-radios-1"
						v-model="daysSelected"
						:options="buttons"
						name="radios-btn-default"
						@input="changeDays"
						buttons
					></b-form-radio-group>
				</div>
				<div>
					<a @click="downloadCSV" style="cursor: pointer">
						<span class="svg-icon svg-icon-lg">
							<inline-svg src="/media/svg/icons/Files/Download.svg" />
						</span>
						Download CSV
					</a>
				</div>
			</div>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div class="row">
					<div class="col-md-9">
						<apexchart type="bar" :height="height" :options="options" :series="series" />
					</div>
					<div class="col-md-3">
						<div class="row" :style="{ height: height + 'px', overflowY: 'scroll' }">
							<table class="table table-striped table-hover fixedTableHead">
								<thead>
									<th>Date</th>
									<th>In</th>
									<th>Out</th>
								</thead>
								<tbody>
									<tr v-for="(d, i) in data" v-bind:key="i">
										<td>{{ d.date }}</td>
										<td>{{ d.in }}B</td>
										<td>{{ d.out }}B</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="row">
							<div class="col-md-6 text-left h4">In Total: {{ in_total }}B</div>
							<div class="col-md-6 text-right h4">Out Total: {{ out_total }}B</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center h3">Total: {{ total_data }}B</div>
						</div>
					</div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { KMG } from '@/helpers';
import swMixin from '@/core/services/mixins/serviceworker.mixin';

export default {
	name: 'SolarwindsDailyGraph',
	mixins: [swMixin],
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	props: {
		name: { Type: String, required: true },
		id: {
			type: Number,
			required: true,
		},
		properties: { type: Object, require: false },
		updated: {
			type: Number,
		},
	},
	data() {
		return {
			buttons: [
				{
					text: '1 Week',
					value: 7,
				},
				{ text: '30 Days', value: 30 },
				{ text: '60 Days', value: 60 },
			],
			csv: '',
			daysSelected: 30,
			loaded: false,
			data: [],
			intstr: '',
			height: 400,
			options: null,
			series: [],
			in_total: 0,
			out_total: 0,
			total_data: 0,
		};
	},
	methods: {
		processData(dd) {
			var dates = Object.keys(dd);
			this.data = [];

			var inn = [];
			var out = [];
			this.in_total = 0;
			this.out_total = 0;
			this.total_data = 0;
			dates.forEach((date) => {
				this.data.push({ date: date, in: KMG(dd[date]['in']), out: KMG(dd[date]['out']) });
				this.in_total += dd[date]['in'];
				this.out_total += dd[date]['out'];
				inn.push(dd[date]['in']);
				out.push(dd[date]['out']);
			});
			this.total_data = KMG(this.in_total + this.out_total);
			this.in_total = KMG(this.in_total);
			this.out_total = KMG(this.out_total);

			this.series = [
				{ name: 'Out', data: out },
				{ name: 'In', data: inn },
			];

			this.options = {
				series: [
					{ name: 'In', data: inn },
					{ name: 'Out', data: out },
				],
				chart: {
					type: 'bar',
					height: 350,
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 8,
						horizontal: false,
					},
				},
				stroke: {
					width: 1,
					colors: ['#fff'],
				},
				colors: ['#434348', '#7db6e9'],
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					categories: dates,
					labels: {
						rotate: -45,
					},
				},
				yaxis: {
					title: {
						text: undefined,
					},
					labels: {
						formatter: function (val) {
							return KMG(val) + 'B';
						},
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return KMG(val) + 'B';
						},
					},
				},
				fill: {
					opacity: 1,
				},
				legend: {
					position: 'top',
					horizontalAlign: 'left',
					offsetX: 40,
				},
			};
		},
		changeDays() {
			this.loaded = false;
			this.$http
				.get(
					`solarwindsdailygraph/${this.id}?site=${this.name}${this.intstr}&days=${this.daysSelected}`
				)
				.then((resp) => {
					this.processData(resp.data.data);
					this.loaded = true;
				});
		},
		downloadCSV() {
			let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += this.csv;
			const data = encodeURI(csvContent);
			const link = document.createElement('a');
			link.setAttribute('href', data);
			link.setAttribute('download', this.name + ' Daily Usage.csv');
			link.click();
		},
		load() {
			this.loaded = false;
			this.intstr = '';
			if (this.properties?.interfacestotal) {
				var keys = Object.keys(this.properties.interfacestotal);
				keys.forEach((intname) => {
					if (this.properties.interfacestotal[intname]) {
						if (this.intstr != '') {
							this.intstr += ',';
						}
						this.intstr += intname;
					}
				});
			}
			if (this.intstr != '') {
				this.intstr = '&interfacestotal=' + this.intstr;
			}
			this.$http
				.get(`solarwindsdailygraph/${this.id}?site=${this.name}${this.intstr}`)
				.then((resp) => {
					this.processData(resp.data.data);
					this.csv = resp.data.csv;
					this.loaded = true;
				});
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	mounted() {
		this.load();
		this.setSWListener('solarwindsdailygraph', (data) => {
			this.processData(data.data);
			this.csv = data.csv;
		});
	},
};
</script>

<style>
.fixed-table-head {
	overflow-y: scroll;
}
table.fixedTableHead thead th {
	position: sticky;
	top: 0;
	background: #fff;
	box-shadow: 0 4px 10px -2px #eef1f5;
}
</style>
